<template>
  <div>
    <div>
      <div class="vx-row">
        <div class="vx-col w-full sm:w-6/12 md:w-6/12 lg:w-6/12 xl:w-6/12 mb-base">
          <Greetings/>
        </div>

        <div class="vx-col w-full sm:w-3/12 md:w-3/12 lg:w-3/12 xl:w-3/12 mb-base" v-if="$can('view_dashboard_total_customers')">
          <TotalCustomer/>
        </div>

        <div class="vx-col w-full sm:w-3/12 md:w-3/12 lg:w-3/12 xl:w-3/12 mb-base" v-if="$can('view_dashboard_total_unit')">
          <TotalUnit/>
        </div>

        <div class="vx-col w-full md:w-2/3 mb-base" v-if="$can('view_dashboard_property_sale')">
          <PropertySaleLineChart/>
        </div>

        <div class="vx-col w-full md:w-1/3 mb-base" v-if="$can('view_dashboard_expenses_budget')">
          <BudgetExpenses/>
        </div>

        <div class="vx-col w-full md:w-1/3 mb-base" v-if="$can('view_dashboard_nilai_kontrak')">
          <NilaiKontrak/>
        </div>

        <div class="vx-col w-full md:w-3/3 mb-base" v-if="$can('view_dashboard_status_unit')">
          <StatusUnitLineChart/>
        </div>

        <div class="vx-col w-full md:w-1/3 mb-base" v-if="$can('view_dashboard_status_customer_prospek')">
          <StatusCustomerProspek/>
        </div>

        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base" v-if="$can('view_dashboard_top_sold_project')">
          <TopSoldProject/>
        </div>

        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base" v-if="$can('view_dashboard_highest_income')">
          <HighestIncome/>
        </div>

        <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base" v-if="$can('view_dashboard_highest_cost')">
          <HighestCost/>
        </div>

        <div class="vx-col w-full md:w-2/3 mb-base" v-if="$can('view_dashboard_revenue')">
          <Revenue/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import analyticsData from '@/views/pages/dashboard/analyticsData'
import ProyekRepository from '@/repositories/master/proyek-repository'
import moduleDashboard from '@/store/modules/dashboard/dashboard.store'

export default {
  components: {
    Greetings: () => import('@/views/pages/dashboard/components/Greetings'),
    TotalCustomer: () => import('@/views/pages/dashboard/components/TotalCustomer'),
    TotalUnit: () => import('@/views/pages/dashboard/components/TotalUnit'),
    Revenue: () => import('@/views/pages/dashboard/components/Revenue'),
    BudgetExpenses: () => import('@/views/pages/dashboard/components/BudgetExpenses'),
    PropertySaleLineChart: () => import('@/views/pages/dashboard/components/PropertySaleLineChart'),
    NilaiKontrak: () => import('@/views/pages/dashboard/components/NilaiKontrak'),
    StatusUnitLineChart: () => import('@/views/pages/dashboard/components/StatusUnitLineChart'),
    StatusCustomerProspek: () => import('@/views/pages/dashboard/components/StatusCustomerProspek'),
    HighestIncome: () => import('@/views/pages/dashboard/components/HighestIncome'),
    HighestCost: () => import('@/views/pages/dashboard/components/HighestCost'),
    TopSoldProject: () => import('@/views/pages/dashboard/components/TopSoldProject')
  },
  mounted () {
    this.getProyeks()
  },
  data () {
    return {
      analyticsData,
      listProyek: []
    }
  },
  methods: {
    getProyeks () {
      ProyekRepository.getAll()
        .then(response => {
          this.listProyek = response.data.data
          this.$store.commit('dashboard/SET_LIST_PROYEK', response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  beforeCreate () {
    this.$store.registerModule(['dashboard'], moduleDashboard)
  },
  beforeDestroy () {
    this.$store.commit('dashboard/main/RESET_STATE')
    this.$store.unregisterModule(['dashboard'])
  }
}
</script>
