const getDefaultState = () => {
  return {
    listProyek: []
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    SET_LIST_PROYEK (state, listProyek) {
      state.listProyek = listProyek
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
